import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/Seo';

function ContactPage() {
  return (

      <section className="px-2 py-6 md:px-16">
      <SEO keywords={[`contato`, `formulario`, `form`]} title="Contact" />

      <div>
        <div>
          <p className="pt-8 uppercase font-bold">
            Televendas e Atendimento.
          </p>
          <p className="py-2">
            (19) 99963-0819 Segunda à quinta, das 7:30h às 17h. Sextas das 7:30h às 16h.
          </p>
        </div>
        <div>
          <p className="pt-8 uppercase font-bold">
            Email
          </p>
          <p className="py-2">
            contato@sostrass.com.br.
          </p>
        </div>
      </div>




      </section>
  );
}

export default ContactPage;
